
import {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    IonModal,
    IonPage,
} from '@ionic/vue'
import { computed, defineComponent, inject, onMounted, onBeforeMount, ref } from 'vue'
import InfoWatiosWatCoins from '@/components/InfoWat.vue'
import ProfileMobileBox from '@/components/ProfileMobileBox.vue'
import Redeem from '@/components/Redeem.vue'
import NotificationsModal from '@/components/NotificationsModal.vue'
import InviteFriendsModal from '@/components/InviteFriendsModal.vue'
import { createAnimation, createGesture } from '@ionic/vue'
import {SplashScreen} from '@capacitor/splash-screen';
import router from '@/router'
import { App, URLOpenListenerEvent} from '@capacitor/app'
import store from '@/store'
import authStore from '@/store/auth.store'
export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonPage,
        IonRouterOutlet,
        IonSplitPane,
        IonModal,
        InfoWatiosWatCoins,
        ProfileMobileBox,
        Redeem,
        NotificationsModal,
        InviteFriendsModal,
    },
    async mounted(){
        
        if(authStore.state.isLoggedIn){
            const residenceCountry: any = authStore.state.userData.residenceCountry
            if(residenceCountry != null && residenceCountry != undefined){
                const secondaryValue = residenceCountry.secondaryValue || 'es'
                this.$i18n.locale = secondaryValue.toLowerCase()
            }
        }
        else this.$i18n.locale = navigator.language || 'es';
    },
    setup() {
        const profileModal: any = ref(null)
        const infoWatModal: any = ref(null)
        const notificationsModal: any = ref(null)
        const slug: any = ref('')

        const recordStore: any = inject('recordStore')
        const configurationStore: any = inject('configurationStore')
        const exchangeStore: any = inject('exchangeStore')
        const authStore: any = inject('authState')
        const notifStore: any = inject('notificationsStore')
        const inviteFriendsStore: any = inject('inviteFriendsStore')

        const enterProfileMobileBoxAnimation = (baseEl: any) => {
            const backdropAnimation = createAnimation()
                .addElement(baseEl.querySelector('ion-backdrop')!)
                .fromTo('opacity', '0', 'var(--backdrop-opacity)')
                .duration(300)

            const wrapperAnimation = createAnimation()
                .addElement(baseEl.querySelector('.modal-wrapper')!)
                .fromTo('transform', 'translateY(100%)', 'translateY(0%)')
                .duration(300)

            return createAnimation()
                .addElement(baseEl)
                .addAnimation([backdropAnimation, wrapperAnimation])
        }
        const leaveProfileMobileBoxAnimation = (baseEl: any) => {
            return enterProfileMobileBoxAnimation(baseEl).direction('reverse')
        }

        const enterInfoWatAnimation = (baseEl: any) => {
            const backdropAnimation = createAnimation()
                .addElement(baseEl.querySelector('ion-backdrop')!)
                .fromTo('opacity', '0', 'var(--backdrop-opacity)')
                .duration(200)

            const wrapperAnimation = createAnimation()
                .addElement(baseEl.querySelector('.modal-wrapper')!)
                .fromTo('transform', 'translateY(100%)', 'translateY(0%)')
                .duration(200)

            return createAnimation()
                .addElement(baseEl)
                .addAnimation([backdropAnimation, wrapperAnimation])
        }

        const leaveInfoWatAnimation = (baseEl: any) => {
            return enterInfoWatAnimation(baseEl).direction('reverse')
        }

        const closeRedeem = () => {
            exchangeStore.clearRedeem()
            authStore.getUserDetails()
        }
        const sleep = (ms: any) => new Promise(r => setTimeout(r, ms));

        onMounted(async () => {
            if (window.location.href.indexOf("/#/testVue/") > -1) {
                window.location.href = "/app/what";
            }
            setTimeout(() => {
                SplashScreen.hide();
            }, 2000);

            App.addListener('appUrlOpen', async function (event: URLOpenListenerEvent) {
                const domain = 'app.wearetesters.com';
                slug.value = event.url.split(domain).pop();
                await sleep(1000);
                if (slug.value !== '') {
                    router.push(slug.value);
                }
            });

            App.addListener('resume', async () => {
                console.log('La aplicación se ha reanudado');
                if (!authStore.state.isLoggedIn) {
                    router.replace({ name: 'LoginPage' });
                } else {
                    await authStore.getUserDetails();
                }
            });
        });


        return {
            userData : computed( ( ) => authStore.state.userData),
            recordStore,
            configurationStore,
            exchangeStore,
            notifStore,
            inviteFriendsStore,
            enterProfileMobileBoxAnimation,
            leaveProfileMobileBoxAnimation,
            enterInfoWatAnimation,
            leaveInfoWatAnimation,
            closeRedeem,
            profileModal,
            infoWatModal,
            notificationsModal,
        }
    },

})
