var routes = [
    {
        path: '',
        redirect: '/login',
    },
    {
        path: '/app',
        component: function () { return import('@/components/Menu.vue'); },
        redirect: '/login',
        children: [
            {
                name: 'profile',
                path: 'profile',
                component: function () { return import('@/views/Profile.vue'); },
            },
            {
                name: 'myAccount',
                path: 'myAccount',
                component: function () { return import('@/views/MyAccount.vue'); },
            },
            {
                name: 'personalData',
                path: 'personalData',
                component: function () { return import('@/views/PersonalData.vue'); },
            },
            {
                name: 'ranking',
                path: 'ranking',
                component: function () { return import('@/views/Ranking.vue'); },
            },
            {
                name: 'tests.index',
                path: 'tests',
                component: function () { return import('@/views/Tests.vue'); },
            },
            {
                name: 'how',
                path: 'how',
                component: function () { return import('@/views/How.vue'); },
            },
            {
                name: 'focusPreview',
                path: 'focus-preview/:id/:slotHash',
                component: function () { return import('@/views/WatFocusPreview.vue'); },
            },
            {
                name: 'watAcademy',
                path: 'watAcademy',
                component: function () { return import('@/views/WatAcademy.vue'); },
            },
            {
                name: 'watAcademyNew',
                path: 'watAcademyNew',
                component: function () { return import('@/views/WatAcademyNew.vue'); },
            },
            {
                name: 'what',
                path: 'what',
                component: function () { return import('@/views/What.vue'); },
            },
            {
                name: 'what.post',
                path: 'what/:id',
                component: function () { return import('@/views/WhatPostScreen.vue'); },
            },
            {
                name: 'record.index',
                path: 'record',
                component: function () { return import('@/views/Points.vue'); },
            },
            {
                name: 'exchange',
                path: 'exchange',
                component: function () { return import('@/views/Exchange.vue'); },
            },
        ],
    },
    {
        name: 'iframePreview',
        path: '/iframe-preview',
        component: function () { return import('@/views/IframePreview.vue'); }
    },
    {
        name: 'login',
        path: '/login',
        component: function () { return import('@/views/auth/Login.vue'); },
    },
    {
        path: '/forgot-password',
        component: function () { return import('@/views/auth/ForgotPassword.vue'); },
    },
    {
        name: 'verify-email',
        path: '/verify-email',
        component: function () { return import('@/views/ConfirmEmail.vue'); },
    },
    {
        path: '/register',
        component: function () { return import('@/views/auth/Register.vue'); },
    },
    {
        name: 'verify-mobile',
        path: '/verify-mobile',
        component: function () { return import('@/views/VerifyMobile.vue'); },
    },
    {
        name: 'validate2fa',
        path: '/2fa',
        component: function () { return import('@/views/auth/Validate2FA.vue'); },
    },
    {
        name: 'add-mobile',
        path: '/add-mobile',
        component: function () { return import('@/views/AddMobile.vue'); },
    },
    {
        name: 'unique-validation',
        path: '/unique-validation',
        component: function () { return import('@/views/UniqueVerification.vue'); },
    },
    {
        name: 'test.show',
        path: '/app/test/:id/show',
        props: true,
        component: function () { return import('@/views/Test.vue'); },
    },
    {
        name: 'focustest',
        path: '/app/test/:id/show/focus',
        props: true,
        component: function () { return import('@/views/Test.vue'); },
    },
    {
        name: 'focus',
        path: '/app/focus/:id/:slotHash',
        component: function () { return import('@/views/WatFocusExecution.vue'); },
    },
    {
        name: 'focusPublic',
        path: '/app/focus/:id/:slotHash/:auth',
        props: { isPublic: true },
        component: function () { return import('@/views/WatFocusExecution.vue'); },
    },
    {
        name: 'focusPreviewPublic',
        path: '/app/focus-preview/:id/:slotHash/:auth',
        props: { isPublic: true },
        component: function () { return import('@/views/WatFocusPreview.vue'); },
    },
    {
        path: '/app/:id',
        component: function () { return import('@/views/Screen.vue'); },
    },
];
export default routes;
