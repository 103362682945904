import { ref, computed, reactive, inject, onBeforeMount } from 'vue';
import { onClickOutside } from '@vueuse/core';
export default {
    name: 'Redeem',
    setup: function () {
        var authStore = inject('authState');
        var exchangeStore = inject('exchangeStore');
        var type = computed(function () { return exchangeStore.state.redeemBox.selectedRedeemType; });
        var target = ref(null);
        onClickOutside(target, function (event) {
            if (event.target !== null &&
                event.target.id !== 'exchangeBoxButton') {
                exchangeStore.setShowRedeem(false);
            }
        });
        var redeemFormData = reactive(exchangeStore.state.redeem.redeemFormData);
        var selectIsSelected = ref(false);
        onBeforeMount(function () {
            redeemFormData.email = authStore.state.userData.email;
        });
        var paypalCheckAmount = function () {
            exchangeStore.state.redeem.modalLoading = true;
            var data = {
                redeemType: exchangeStore.state.redeemBox.types.PAYPAL,
                pointsRedeem: parseInt(redeemFormData.amount),
            };
            exchangeStore.check(data);
        };
        var RedeemSubmit = function () {
            if (type.value == exchangeStore.state.redeemBox.types.PAYPAL) {
                paypalCheckAmount();
            }
            else {
                exchangeStore.state.redeem.modalLoading = true;
                exchangeStore.state.redeem.redeemConfirmation.showSuccess =
                    false;
                exchangeStore.state.redeem.redeemConfirmation.showError = false;
                var data = {
                    redeemType: type.value,
                    pointsRedeem: parseInt(redeemFormData.amount),
                    testerRedeemData: {
                        identityDocType: {
                            elementId: parseInt(authStore.state.userData.identityDocType
                                .elementId),
                        },
                        identityDocNum: authStore.state.userData.identityDocNum,
                        name: authStore.state.userData.name,
                        surname1: authStore.state.userData.surname1,
                        surname2: authStore.state.userData.surname2,
                        address: authStore.state.userData.address,
                        locality: authStore.state.userData.locality,
                        postalCode: authStore.state.userData.postalCode,
                    },
                };
                exchangeStore.redeem(data);
            }
        };
        var paypalRedeemSubmit = function () {
            exchangeStore.state.redeem.modalLoading = true;
            exchangeStore.state.redeem.redeemConfirmation.showSuccess = false;
            exchangeStore.state.redeem.redeemConfirmation.showError = false;
            var data = {
                redeemType: exchangeStore.state.redeemBox.types.PAYPAL,
                pointsRedeem: parseInt(redeemFormData.amount),
                paypalEmail: redeemFormData.email,
                testerRedeemData: {
                    identityDocType: {
                        elementId: parseInt(authStore.state.userData.identityDocType.elementId),
                    },
                    identityDocNum: authStore.state.userData.identityDocNum,
                    name: authStore.state.userData.name,
                    surname1: authStore.state.userData.surname1,
                    surname2: authStore.state.userData.surname2,
                    address: authStore.state.userData.address,
                    locality: authStore.state.userData.locality,
                    postalCode: authStore.state.userData.postalCode,
                },
            };
            exchangeStore.paypalRedeem(data);
        };
        var minAmount = function (type) {
            switch (type) {
                case exchangeStore.state.redeemBox.types.PAYPAL:
                    return 10;
                case exchangeStore.state.redeemBox.types.AMAZON:
                    return 10;
                case exchangeStore.state.redeemBox.types.ATRAPALO:
                    return 25;
                case exchangeStore.state.redeemBox.types.ASOS:
                    return 10;
                case exchangeStore.state.redeemBox.types.TREE_NATION:
                    return 10;
                default:
                    return 0;
            }
        };
        var userValidData = function () {
            return (authStore.state.userData.name &&
                authStore.state.userData.surname1 &&
                authStore.state.userData.surname2 &&
                authStore.state.userData.bornDate &&
                authStore.state.userData.identityDocNum &&
                authStore.state.userData.postalCode &&
                authStore.state.userData.address &&
                authStore.state.userData.locality);
        };
        var userValidEmail = function () {
            return (authStore.state.userData.email &&
                (!authStore.state.userData.emailNeedsValidation || authStore.state.userData.emailValidated));
        };
        var userValidIdDocType = function () {
            if (authStore.state.userData.identityDocType != null && authStore.state.userData.identityDocType.elementId != null) {
                return true;
            }
            else {
                return false;
            }
        };
        return {
            userData: computed(function () { return authStore.state.userData; }),
            redeemConfirmation: reactive(exchangeStore.state.redeem.redeemConfirmation),
            redeemFormData: redeemFormData,
            modalLoading: computed(function () { return exchangeStore.state.redeem.modalLoading; }),
            RedeemSubmit: RedeemSubmit,
            paypalRedeemSubmit: paypalRedeemSubmit,
            type: type,
            redeemBox: computed(function () { return exchangeStore.state.redeemBox; }),
            minAmount: minAmount,
            userValidData: userValidData,
            userValidEmail: userValidEmail,
            userValidIdDocType: userValidIdDocType,
            selectIsSelected: selectIsSelected,
            target: target,
        };
    },
};
