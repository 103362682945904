// src/router/index.ts
import {
    createRouter,
    createWebHistory,
} from '@ionic/vue-router'
import {
    NavigationGuardNext,
    RouteLocationNormalized,
    RouteLocationRaw,
} from 'vue-router'

import routes from './routes'
import authStore from '@/store/auth.store'
import axios from '../config/axiosConfig'
import { Preferences } from '@capacitor/preferences'
import { loadCitiServiPixel } from '../../../commonvue/src/assets/scripts/citiservipixel'

type AuthData = {
    isLoggedIn: boolean
    appToken: string
    authToken: string
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

/**
 * Función para validar 2FA.
 */
const check2FA = async () => {
    const needs2FA = await Preferences.get({ key: 'needs2FA' })
    if (!needs2FA.value) return needs2FA

    const currentTimeStamp = Date.now()
    const maxMillisecondsValid = 5 * 60 * 1000
    const needs2FAValuesAsNumber = Number(needs2FA.value)

    if (
        isNaN(needs2FAValuesAsNumber) ||
        currentTimeStamp > needs2FAValuesAsNumber + maxMillisecondsValid
    ) {
        await Preferences.remove({ key: 'needs2FA' })
        await authStore.logUserOut()
        return Preferences.get({ key: 'needs2FA' })
    }
    return needs2FA
}

/**
 * Función que gestiona redirecciones para URLs legacy basadas en el hash o path.
 */
function handleLegacyRoutes(
    to: RouteLocationNormalized
): { external?: boolean; route?: RouteLocationRaw; path?: string } | null {
    if (to.hash.includes('#/testVue/')) {
        // Redirección para URLs legacy de test
        if (to.hash.includes('?')) {
            const params = to.hash.substring(to.hash.indexOf('?') + 1)
            let testId = to.hash.replace('#/testVue/', '')
            testId = testId.substring(0, testId.indexOf('?'))
            console.log('Legacy test params:', params)
            return { route: 'app/test/' + testId + '/show?' + params }
        } else {
            const testId = to.hash.replace('#/testVue/', '')
            console.log(
                'Detected old test url, redirecting to /app/test/' +
                    testId +
                    '/show'
            )
            return { route: 'app/test/' + testId + '/show' }
        }
    } else if (to.hash.includes('#/entrar')) {
        if (to.hash.includes('?')) {
            const params = to.hash.substring(to.hash.indexOf('?') + 1)
            return { route: 'entrar?' + params }
        }
        return null
    } else if (to.hash.includes('#/registro')) {
        console.log('Detected old register url, redirecting to /register')
        if (to.hash.includes('?')) {
            const params = to.hash.substring(to.hash.indexOf('?') + 1)
            return { route: 'register?' + params }
        }
        return { route: 'register' }
    } else if (to.hash.includes('#/contratos')) {
        let contractId = to.hash.replace('#/contratos/', '')
        console.log(
            'Detected old powerteam url, redirecting externally to powerteam'
        )
        return {
            external: true,
            path:
                'https://powerteam.wearetesters.com/#/contratos/' + contractId,
        }
    } else if (to.path.startsWith('/empresas')) {
        return { external: true, path: 'https://suite.wearetesters.com' }
    } else if (to.path.startsWith('/powerteam')) {
        return { external: true, path: 'https://powerteam.wearetesters.com' }
    }
    return null
}

/**
 * Función guard para redirecciones según estado de autenticación.
 */
const guard = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    authData: AuthData
): Promise<void | Error | boolean | RouteLocationRaw> => {
    if (to.name === 'iframePreview') return // No aplicar guard en iframePreview

    const needs2fa = await check2FA()
    if (
        authData.isLoggedIn &&
        to.path !== '/' &&
        to.path !== '/2fa' &&
        needs2fa.value
    ) {
        return { name: 'validate2fa' }
    } else if (authData.isLoggedIn && to.path === '/2fa' && needs2fa.value) {
        return
    }

    if (
        to.path === '/entrar' &&
        authData.isLoggedIn &&
        authStore.state.userData.profileCompleted
    ) {
        return { name: 'tests.index' }
    }

    if (authData.isLoggedIn) {
        loadCitiServiPixel(authStore.state.userData.email)
    }

    if (
        to.path === '/login' &&
        authData.isLoggedIn &&
        authStore.state.userData.profileCompleted
    ) {
        return { name: 'tests.index' }
    }

    if (
        authData.isLoggedIn &&
        to.path !== '/' &&
        to.path !== '/verify-mobile' &&
        to.path !== '/unique-validation' &&
        ((!authStore.state.userData.mobileValidated &&
            authStore.state.userData.mobileValidationRequired &&
            authStore.state.userData.mobileNum != null) ||
            !authStore.state.userData.profileCompleted)
    ) {
        return { name: 'verify-mobile' }
    }

    if (
        to.path !== '/register' &&
        to.path !== '/forgot-password' &&
        to.path !== '/login' &&
        to.path !== '/verify-email' &&
        to.name !== 'focusPreview' &&
        to.name !== 'focusPublic' &&
        to.name !== 'focusPreviewPublic' &&
        !authData.isLoggedIn
    ) {
        return { path: '/login' }
    }
    return
}

/**
 * BeforeEach global unificado.
 */
router.beforeEach(async (to, from) => {
    // 1. Manejo de URLs legacy
    const legacy = handleLegacyRoutes(to)
    if (legacy) {
        if (legacy.external && legacy.path) {
            window.location.href = legacy.path
            return false
        }
        if (legacy.route) return legacy.route
    }

    // 2. Procesa query parameters para tokens en rutas específicas
    if (
        (to.path === '/entrar' ||
            to.name === 'focustest' ||
            to.name === 'focusPreview') &&
        typeof to.query.authToken === 'string' &&
        to.query.authToken !== ''
    ) {
        await Preferences.set({ key: 'authToken', value: to.query.authToken })
        await Preferences.set({ key: 'appToken', value: 'appTokenTemp' })
    }

    // 3. Carga los tokens almacenados
    await authStore.loadStorageTokens()
    if (authStore.state.isLoggedIn && !authStore.state.deviceRegistered) {
        authStore.setDeviceRegistered()
    }

    const authData: AuthData = {
        isLoggedIn: authStore.state.isLoggedIn,
        appToken: authStore.state.appToken,
        authToken: authStore.state.authToken,
    }

    // 4. Actualiza los headers de axios
    axios.defaults.headers.common['authToken'] = authData.authToken
    axios.defaults.headers.common['appToken'] = authData.appToken

    // 5. Si hay token y aún no se han cargado los datos de usuario, intentar cargarlos
    const needs2fa = await check2FA()
    if (
        authData.authToken !== '' &&
        !authStore.state.userDataLoaded &&
        !needs2fa.value
    ) {
        await authStore.getUserDetails()
    }

    // 6. Ejecuta el guard de autenticación y retorna su resultado
    return guard(to, from, authData)
})

export default router
