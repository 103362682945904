var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defineComponent, ref, computed } from "vue";
import * as yup from "yup";
import { useField } from "vee-validate";
import i18n from '@/i18n';
import axios from "@/config/axiosConfig";
export default defineComponent({
    emits: ['closeContactModal'],
    setup: function () {
        var _this = this;
        var showError = ref(false);
        var showSuccess = ref(false);
        var loading = ref(false);
        var submited = ref(false);
        var subjectIsSelected = ref(false);
        var messageIsSelected = ref(false);
        var _a = useField("subject", yup.string().required(i18n.global.t("FIELD_REQUIRED"))), subjectError = _a.errorMessage, subject = _a.value;
        var _b = useField("message", yup.string().required(i18n.global.t("FIELD_REQUIRED"))), messageError = _b.errorMessage, message = _b.value;
        var formValid = computed(function () {
            return !!subject.value && !subjectError.value &&
                !!message.value && !messageError.value;
        });
        var contact = function (data) { return __awaiter(_this, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.post("/tester/contact", data)];
                    case 1:
                        response = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        return [2 /*return*/, false];
                    case 3:
                        if ((response === null || response === void 0 ? void 0 : response.status) != 200)
                            return [2 /*return*/, false];
                        return [2 /*return*/, true];
                }
            });
        }); };
        var contactSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, succes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!formValid.value) {
                            return [2 /*return*/];
                        }
                        loading.value = true;
                        data = {
                            title: subject.value,
                            body: message.value,
                        };
                        return [4 /*yield*/, contact(data)];
                    case 1:
                        succes = _a.sent();
                        if (!succes) {
                            showSuccess.value = false;
                            showError.value = true;
                            submited.value = true;
                            loading.value = false;
                            return [2 /*return*/];
                        }
                        showSuccess.value = true;
                        showError.value = false;
                        submited.value = true;
                        loading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var closeContactModal = function () {
            submited.value = false;
            showError.value = false;
            showSuccess.value = false;
            subject.value = '';
            message.value = '';
        };
        return {
            subjectError: subjectError,
            subject: subject,
            messageError: messageError,
            message: message,
            showError: showError,
            showSuccess: showSuccess,
            loading: loading,
            contactSubmit: contactSubmit,
            subjectIsSelected: subjectIsSelected,
            messageIsSelected: messageIsSelected,
            submited: submited,
            closeContactModal: closeContactModal
        };
    },
});
