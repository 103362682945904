import axios from 'axios';
import queryString from 'query-string';
import { pickBy, assign, identity } from "lodash";
var config = {
    'withCredentials': true,
    'Access-Control-Allow-Credentials': true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'authToken': null
    },
    responseType: "",
    data: {}
};
var updateConfig = function (headers) {
    config = pickBy(assign({}, config, headers), identity);
    if (config.interceptors) {
        config.interceptors.map(function (int) {
            if (int.type == 'request')
                axios.interceptors.request.use(int.success_cb, int.error_cb);
            if (int.type == 'response')
                axios.interceptors.response.use(int.success_cb, int.error_cb);
        });
    }
};
var setAuthToken = function () {
    updateConfig({
        headers: {
            'authToken': config.token(),
        }
    });
};
var actions = {
    setConfig: function (customConfig) {
        updateConfig(customConfig);
    },
    getConfig: function () {
        return config;
    },
    get: function (path, params, headers) {
        setAuthToken();
        if (headers)
            updateConfig(headers);
        var query = '';
        if (params) {
            query = '?' + queryString.stringify(params);
        }
        if (headers == undefined) {
            config.responseType = "";
        }
        return axios.get(path + query, config);
    },
    post: function (path, body, headers) {
        setAuthToken();
        if (headers)
            updateConfig(headers);
        if (headers == undefined) {
            config.responseType = "";
        }
        return axios.post(path, body, config);
    },
    postFile: function (path, body, headers) {
        setAuthToken();
        updateConfig(headers);
        config.responseType = "";
        config.data = undefined;
        return axios.post(path, body, config);
    },
    put: function (path, body, headers) {
        setAuthToken();
        if (headers)
            updateConfig(headers);
        if (headers == undefined) {
            config.responseType = "";
        }
        return axios.put(path, body, config);
    },
    delete: function (path, body, headers) {
        setAuthToken();
        if (headers)
            updateConfig(headers);
        if (headers == undefined) {
            config.responseType = "";
        }
        config.data = body;
        return axios.delete(config.baseURL + path, { headers: config.headers, data: body });
    },
};
var ApiService = actions;
export default ApiService;
