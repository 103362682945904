var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ApiService from '../services/api.service';
import { VueCookieNext } from 'vue-cookie-next';
export var FETCH_FOCUS_DATA_MODERATOR = "FETCH_FOCUS_DATA_MODERATOR";
export var FETCH_FOCUS_DATA_OBS = "FETCH_FOCUS_DATA_OBS";
export var FETCH_TESTER_JOIN_SLOT = "FETCH_TESTER_JOIN_SLOT";
export var GRANT_TESTER_ACCESS = "GRANT_TESTER_ACCESS";
export var FETCH_WAT_FOCUS_DATA = "FETCH_WAT_FOCUS_DATA";
export var FETCH_ONLINE_USERS = "FETCH_ONLINE_USERS";
export var FETCH_WAT_FOCUS_USERS = "FETCH_WAT_FOCUS_USERS";
export var SAVE_WAT_FOCUS_PREVIOUS_CONNECTIVITY = "SAVE_WAT_FOCUS_PREVIOUS_CONNECTIVITY";
export var SAVE_OBSERVATOR_NAME = "SAVE_OBSERVATOR_NAME";
export var FETCH_FOCUS_TESTER = "FETCH_FOCUS_TESTER";
export var SET_ONLINE_USERS = "SET_ONLINE_USERS";
export var ADD_FOCUS_OBSERVER_CHAT_MESSAGE = "ADD_FOCUS_OBSERVER_CHAT_MESSAGE";
export var ADD_FOCUS_CHAT_MESSAGE = "ADD_FOCUS_CHAT_MESSAGE";
export var WAT_FOCUS_PUT_MESSAGE = "WAT_FOCUS_PUT_MESSAGE";
export var WAT_FOCUS_PUT_MESSAGE_OBSERVATOR = "WAT_FOCUS_PUT_MESSAGE_OBSERVATOR";
export var SAVE_FOCUS_CHAT_MESSAGES = "SAVE_FOCUS_CHAT_MESSAGES";
export var SAVE_FOCUS_OBSERVER_CHAT_MESSAGES = "SAVE_FOCUS_OBSERVER_CHAT_MESSAGES";
export var FETCH_WAT_FOCUS_CHAT_MESSAGES = "FETCH_WAT_FOCUS_CHAT_MESSAGES";
export var FETCH_WAT_FOCUS_CHAT_MESSAGES_OBS = "FETCH_WAT_FOCUS_CHAT_MESSAGES_OBS";
export var FETCH_WAT_FOCUS_FILE = "FETCH_WAT_FOCUS_FILE";
export var PUT_WAT_FOCUS_COMMENT = "PUT_WAT_FOCUS_COMMENT";
export var WAT_FOCUS_DELETE_CHAT_MESSAGE = "WAT_FOCUS_DELETE_CHAT_MESSAGE";
export var WAT_FOCUS_PUT_MESSAGE_PUBLIC = "WAT_FOCUS_PUT_MESSAGE_PUBLIC";
var state = {
    onlineUsers: {},
    focusChatMessagesObs: [],
    focusChatMessages: []
};
var getters = {
    onlineUsers: function (state) {
        return state.onlineUsers;
    },
    focusChatMessages: function (state) {
        return state.focusChatMessages;
    },
    focusChatMessagesObs: function (state) {
        return state.focusChatMessagesObs;
    }
};
var actions = {
    FETCH_FOCUS_DATA_MODERATOR: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .get("/client/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/videochatdata"), null)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    FETCH_FOCUS_DATA_OBS: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .get("/client/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/videochatdataobs"), null)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    GRANT_TESTER_ACCESS: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .post("/client/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/tester/").concat(payload.testerId), payload.grantAccess)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    FETCH_TESTER_JOIN_SLOT: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, endPoint;
            return __generator(this, function (_a) {
                headers = {};
                if (payload.isPublic) {
                    headers = { headers: { 'tesVisToken': payload.visitorToken } };
                }
                endPoint = payload.isPublic ? "/tester/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/public") : "/tester/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash);
                return [2 /*return*/, ApiService
                        .get(endPoint, null, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    FETCH_WAT_FOCUS_DATA: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .get("/watFocus/".concat(payload.id, "/slot/").concat(payload.slotHash), null)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    FETCH_ONLINE_USERS: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .get("/client/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/onlineusers"), null)
                        .then(function (_a) {
                        var data = _a.data;
                        context.commit(SET_ONLINE_USERS, data.onlineUsers);
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    FETCH_WAT_FOCUS_USERS: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .get("/client/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/users"), null)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    SAVE_WAT_FOCUS_PREVIOUS_CONNECTIVITY: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (payload.isPublic) {
                    headers = { headers: { 'tesVisToken': payload.visitorToken } };
                }
                return [2 /*return*/, ApiService
                        .post("/tester/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/connectivity"), payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    SAVE_OBSERVATOR_NAME: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .post("/client/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/observator"), payload.data)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    FETCH_FOCUS_TESTER: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                VueCookieNext.setCookie('testerVisitorToken', payload.visitorToken);
                headers = { headers: { 'tesVisToken': payload.visitorToken } };
                return [2 /*return*/, ApiService
                        .get("/tester/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/tester"), null, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    WAT_FOCUS_PUT_MESSAGE_OBSERVATOR: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .post("/client/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/chat/").concat(payload.isModerator ? 'toObserver' : 'fromObserver'), payload.data)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return Promise.reject(response);
                    })];
            });
        });
    },
    WAT_FOCUS_PUT_MESSAGE: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .post("".concat(payload.isModerator ? '/client' : '/tester', "/watfocus/").concat(payload.id, "/slot/").concat(payload.slotHash, "/chat"), payload.data)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    WAT_FOCUS_PUT_MESSAGE_PUBLIC: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = { headers: { 'tesVisToken': VueCookieNext.getCookie("testerVisitorToken") } };
                return [2 /*return*/, ApiService
                        .post("/tester/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/chatPublic"), payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    FETCH_WAT_FOCUS_CHAT_MESSAGES: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .get("".concat(payload.isModerator ? '/client' : '/tester', "/watfocus/").concat(payload.id, "/slot/").concat(payload.slotHash, "/chat"), null)
                        .then(function (_a) {
                        var data = _a.data;
                        context.commit(SAVE_FOCUS_CHAT_MESSAGES, data);
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    FETCH_WAT_FOCUS_CHAT_MESSAGES_OBS: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .get("/client/watfocus/".concat(payload.id, "/slot/").concat(payload.slotHash, "/chatObs"), null)
                        .then(function (_a) {
                        var data = _a.data;
                        context.commit(SAVE_FOCUS_OBSERVER_CHAT_MESSAGES, data);
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    FETCH_WAT_FOCUS_FILE: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .get('/experienceLabs/client/archive/' + payload.archiveId, null)];
            });
        });
    },
    PUT_WAT_FOCUS_COMMENT: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .post('/client/watfocus/' + payload.watFocusId + '/slot/' + payload.slotHash + '/videoComment', payload.data)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    WAT_FOCUS_DELETE_CHAT_MESSAGE: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .delete('/client/watfocus/chat/messages', { messageIds: payload.messageIds })];
            });
        });
    },
};
var mutations = {
    SET_ONLINE_USERS: function (state, data) {
        state.onlineUsers = data;
    },
    SAVE_FOCUS_OBSERVER_CHAT_MESSAGES: function (state, data) {
        state.focusChatMessagesObs = data.messages;
    },
    SAVE_FOCUS_CHAT_MESSAGES: function (state, data) {
        state.focusChatMessages = data.messages;
    },
    ADD_FOCUS_OBSERVER_CHAT_MESSAGE: function (state, data) {
        state.focusChatMessagesObs.push(data);
    },
    ADD_FOCUS_CHAT_MESSAGE: function (state, data) {
        state.focusChatMessages.push(data);
    }
};
export default {
    actions: actions,
    state: state,
    getters: getters,
    mutations: mutations
};
