import { defineComponent } from 'vue';
import moment from 'moment';
export default defineComponent({
    emits: ['openContactModal'],
    setup: function () {
        var currentDate = new Date();
        var androidDownloadLink = 'https://play.google.com/store/apps/details?id=com.byv.inlacou.wat&hl=es';
        var iosDownloadLink = 'https://itunes.apple.com/us/app/we-are-testers/id1096049567?l=es&ls=1&mt=8';
        var getYear = function () {
            return moment(currentDate).year();
        };
        return {
            currentDate: currentDate,
            getYear: getYear,
            androidDownloadLink: androidDownloadLink,
            iosDownloadLink: iosDownloadLink
        };
    },
});
