import { defineComponent, inject, ref } from 'vue';
import InfoWatDescription from '@/components/InfoWatDescription.vue';
import InfoWatGet from '@/components/InfoWatGet.vue';
import InfoWatStatus from '@/components/InfoWatStatus.vue';
import InfoWatPrivileges from '@/components/InfoWatPrivileges.vue';
import { onClickOutside } from '@vueuse/core';
export default defineComponent({
    components: {
        InfoWatDescription: InfoWatDescription,
        InfoWatGet: InfoWatGet,
        InfoWatStatus: InfoWatStatus,
        InfoWatPrivileges: InfoWatPrivileges,
    },
    setup: function () {
        var recordStore = inject('recordStore');
        var selectedOption = ref('InfoWatDescription');
        var target = ref(null);
        onClickOutside(target, function (event) {
            if (event.target !== null &&
                event.target.id !== 'infoPointsActivator') {
                recordStore.setShowinfoWatiosWatCoins(false);
            }
        });
        return {
            selectedOption: selectedOption,
            target: target,
        };
    },
});
