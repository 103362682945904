var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import ApiService from '../services/api.service';
import { VueCookieNext } from 'vue-cookie-next';
var getters = {
    isAuthenticatedTester: function () {
        if (process.env.VUE_APP_ENVIROMENT == 'WAT_CLIENTS')
            return false;
        else if (process.env.VUE_APP_ENVIROMENT == 'WAT_TESTERS')
            return true;
        else if (process.env.VUE_APP_ENVIROMENT == 'WAT_EXECUTION') {
            if (VueCookieNext.getCookie('authToken'))
                return true;
        }
        return false;
    },
    isVisitorTester: function () {
        // WAT_EXECUTION -> conditional
        if (process.env.VUE_APP_ENVIROMENT == 'WAT_CLIENTS')
            return false;
        else if (process.env.VUE_APP_ENVIROMENT == 'WAT_TESTERS')
            return false;
        else if (process.env.VUE_APP_ENVIROMENT == 'WAT_EXECUTION') {
            if (VueCookieNext.getCookie('testerVisitorToken'))
                return true;
        }
        return false;
    },
    testerVisitorToken: function () {
        if (process.env.VUE_APP_ENVIROMENT == 'WAT_CLIENTS')
            return VueCookieNext.getCookie('testerVisitorToken');
        else if (process.env.VUE_APP_ENVIROMENT == 'WAT_TESTERS')
            return undefined;
        else if (process.env.VUE_APP_ENVIROMENT == 'WAT_EXECUTION')
            return VueCookieNext.getCookie('testerVisitorToken');
    }
};
var actions = {
    FETCH_TEST_INFO: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var endPoint, headers;
            return __generator(this, function (_a) {
                endPoint = '';
                headers = { 'Accept-Language': payload.lang != undefined ? payload.lang : "es" };
                if (payload.executionType == "TEST") {
                    headers.authToken = ApiService.getConfig().token();
                    endPoint = '/execution/test/';
                }
                if (payload.executionType == "TEST_PUBLIC") {
                    headers.tesVisToken = ApiService.getConfig().testerVisitorToken();
                    endPoint = '/execution/test/public/';
                }
                if (payload.executionType == "PREVIEW_PUBLIC") {
                    headers.tesVisToken = ApiService.getConfig().testerVisitorToken();
                    endPoint = '/execution/client/public/';
                }
                return [2 /*return*/, ApiService
                        .get(endPoint + payload.testId, null, { headers: headers })];
            });
        });
    },
    START_TEST_EXEC: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post('/execution' + (isNaN(payload.testId) ? '/public/' : '/') + payload.testId, payload.data, headers)];
            });
        });
    },
    START_PREVIEW_EXEC: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = { headers: { 'visToken': ApiService.getConfig().testerVisitorToken() } };
                return [2 /*return*/, ApiService
                        .post('/execution/' + payload.testId + '/publicPreview', payload.data, headers)];
            });
        });
    },
    FETCH_TEST_EXECUTION: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post('/execution' + (isNaN(payload.testId) ? '/public/' : '/') + payload.testId + '/continue', payload.data, headers)];
            });
        });
    },
    ADD_TEST_SEGMENTATION: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post('/execution/' + payload.testId + '/publicSegmentation', payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    FETCH_NEXT_QUESTION_BLOCK: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .get('/execution' + (isNaN(payload.testId) ? '/public/' : '/') + payload.testId + '/nextQuestionBlock', null, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    ADD_TEST_QUESTION: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post('/execution/' + (isNaN(payload.testId) ? 'public/' : 'test/') + payload.testId + '/task/' + payload.taskNum + '/question/' + payload.questionId, payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    ADD_TEST_LANGUAGE: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .put('/execution' + (isNaN(payload.testId) ? '/public/' : '/') + payload.testId + '/language', { language: payload.lang }, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    SET_EMBEDDED_TRAP: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post('/execution' + (isNaN(payload.testId) ? '/public/' : '/') + payload.testId + '/trap', payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    SET_EMBEDDED_QUOTA: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post('/test/embedded/' + payload.testId + '/' + (isNaN(payload.testId) ? 'publicQuotas' : 'quotas'), null, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    SET_EMBEDDED_QUALITY_KILLER: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post('/test/embedded/' + payload.testId + '/' + (isNaN(payload.testId) ? 'publicQualityKiller' : 'qualityKiller'), null, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    SET_TASK_VALIDATION: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post('/execution/' + (isNaN(payload.testId) ? 'public' : 'test') + "/" + payload.testId + "/task/" + payload.taskNum + "/validation", {}, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    DELETE_PREVIOUS_ANSWER: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                return [2 /*return*/, ApiService
                        .delete('/execution/' + payload.testId + '/lastPreviewAnswer', null, headers)];
            });
        });
    },
    ADD_USER_DATA: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .put('/userDataConfirmation', payload.data)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    FETCH_TESTER_COUNTRY: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .get('https://ipinfo.io/json', null, {
                        'withCredentials': false,
                        'Access-Control-Allow-Credentials': false,
                    })
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    UPLOAD_IMAGE_URL: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .postFile('/file', payload.formData, headers)];
            });
        });
    },
    getVisitorToken: function () {
        return ApiService
            .get('/visitorToken', null)
            .then(function (_a) {
            var data = _a.data;
            if (process.env.VUE_APP_ENVIROMENT == 'WAT_CLIENTS' || process.env.VUE_APP_ENVIROMENT == 'WAT_EXECUTION')
                VueCookieNext.setCookie('testerVisitorToken', data.visitorToken);
            return data;
        });
    },
    FETCH_TESTER_DATA: function (context, payload) {
        return ApiService
            .get('/tester', null)
            .then(function (_a) {
            var data = _a.data;
            return data;
        })
            .catch(function (_a) {
            var response = _a.response;
            return response;
        });
    },
    getUserDetails: function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, ApiService
                        .get('/tester', null)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    SAVE_UX_QUANTI_DATA: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, path;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                path = "/execution/test/".concat(payload.testId, "/task/").concat(payload.taskNum, "/uxQuantiData");
                if (isNaN(payload.testId))
                    path = "/execution/public/test/".concat(payload.testId, "/task/").concat(payload.taskNum, "/uxQuantiData");
                return [2 /*return*/, ApiService
                        .post(path, payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    SAVE_CLICK_TEST_DATA: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, path;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                path = "/execution/test/".concat(payload.testId, "/task/").concat(payload.taskNum, "/clickTestData");
                if (isNaN(payload.testId))
                    path = "/execution/public/test/".concat(payload.testId, "/task/").concat(payload.taskNum, "/clickTestData");
                return [2 /*return*/, ApiService
                        .post(path, payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    SET_TIMEOUT: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, path;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                path = "/execution/test/".concat(payload.testId, "/timeout");
                if (isNaN(payload.testId))
                    path = "/execution/public/test/".concat(payload.testId, "/timeout");
                return [2 /*return*/, ApiService
                        .post(path, payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    FETCH_TEST_FOCUS_CALENDAR: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .get("/execution/".concat(isNaN(payload.testId) ? 'public' : 'test', "/").concat(payload.testId, "/watfocus"), payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    SAVE_WAT_FOCUS_TEST_EXEC_CONNECTIVITY: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post("/execution/".concat(isNaN(payload.testId) ? 'public' : 'test', "/").concat(payload.testId, "/watfocus/connectivity"), payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    SAVE_WAT_FOCUS_TEST_EXEC_CALENDAR: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post("/execution/".concat(isNaN(payload.testId) ? 'public' : 'test', "/").concat(payload.testId, "/watfocus/calendar"), payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    },
    SAVE_WAT_FOCUS_TESTER: function (context, payload) {
        return __awaiter(this, void 0, void 0, function () {
            var headers;
            return __generator(this, function (_a) {
                headers = {};
                if (isNaN(payload.testId)) {
                    headers = { headers: { 'tesVisToken': ApiService.getConfig().testerVisitorToken() } };
                }
                return [2 /*return*/, ApiService
                        .post("/execution/public/".concat(payload.testId, "/watfocus/user"), payload.data, headers)
                        .then(function (_a) {
                        var data = _a.data;
                        return data;
                    })
                        .catch(function (_a) {
                        var response = _a.response;
                        return response;
                    })];
            });
        });
    }
};
export default {
    actions: actions,
    getters: getters
};
